<template>
  <el-popover trigger="hover" width="200px" :placement="mainPlacement" ref="mainPopover" :onShow="onshow" :onAfterLeave="onhide">
    <template #reference>
      <div class="members" @mouseenter="loadOrganization" ref="inner">
        <slot name="reference"></slot>
      </div>
    </template>
    <el-scrollbar max-height="400px" ref="content">
      <div v-for="(user,index) in members" :key="index">
        <el-popover :placement="subPlacement" trigger="hover"  width="200px" :onShow="onSubMenuShow" :onAfterLeave="onSubMenuHide"
                    v-if="user.managerType==1 && organizationMemberMap[user.managerId]&& organizationMemberMap[user.managerId].length>0">
          <template #reference>
            <div class="user">
              <user-icon :url="user.headImgUrl" :name="user.userName" :uid="user.userName"></user-icon>
              <div style="display: flex;flex-direction: column;flex: 1;margin-left: 10px" class="name">
                <span class="truncate" style="font-size: 12px;font-weight: bold" :title="user.userName">{{ user.userName ? user.userName : user.uid }}</span>
                <span
                    class="truncate"
                    style="font-size: 12px;"
                      v-if="user.managerType==1&& organizationMemberMap[user.managerId]">{{ organizationMemberMap[user.managerId].length + "people" }}</span>
              </div>

              <div v-if="user.managerType==1"><i class="el-icon-caret-right"></i></div>
            </div>
          </template>
          <el-scrollbar ref="sub"
               max-height="400px"
               style="max-height: 400px;overflow-y: auto;overflow-x: hidden" @mouseenter="$refs.mainPopover.show()" @mouseleave="$refs.mainPopover.hide()">
            <div class="organization-member" v-for="(item,index2) in organizationMemberMap[user.managerId]"
                 :key="index2">
              <user-icon :url="item.headImgUrl" :name="item.userName" :uid="item.userName"></user-icon>
<!--              <div v-if="item" :style="{'background-color':item.headImgUrl?'unset':getHashColor(item.uid)}"-->
<!--                   class="stage-order">-->
<!--                <img v-if="item.headImgUrl" :src="item.headImgUrl" width="28" height="28"/>-->
<!--                <span-->
<!--                    class="truncate"-->
<!--                    v-else>{{ item.userName ? getUserName(item.userName).substr(0, 1) : item.uid.substr(0, 1) }}</span>-->
<!--              </div>-->
              <div style="margin-left: 10px" v-if="item">
                <span class="truncate" :title="item.userName">{{ item.userName }}</span>
              </div>
            </div>
          </el-scrollbar>
        </el-popover>
        <div v-else class="user">
          <user-icon :url="user.headImgUrl" :name="user.userName" :uid="user.userName"></user-icon>
          <div style="display: flex;flex-direction: column;flex: 1;margin-left: 10px" class="name">
            <span class="truncate" style="font-size: 12px;font-weight: bold" :title="user.userName">{{ user.userName ? user.userName : user.uid }}</span>
            <span class="truncate" style="font-size: 12px;"
                  v-if="user.managerType==1&& organizationMemberMap[user.managerId]">{{ organizationMemberMap[user.managerId].length + "people" }}</span>
          </div>

          <div v-if="user.managerType==1"><i class="el-icon-caret-right"></i></div>
        </div>
      </div>

    </el-scrollbar>
  </el-popover>
</template>

<script>
import {getHashColor, getUserName} from "../util";
import {getOrganizationUser} from "../api/api";
import UserIcon from "./UserIcon";

export default {
  name: "Members",
  components: {UserIcon},
  props: ["idx", "members","main_placement","sub_placement"],
  data() {
    return {
      getHashColor,
      showItemAtBottom: false,
      showMenuAtBottom: false,
      showMenu: false,
      showItem: false,
      getUserName,
      organizationMemberMap: {},
      mainPlacement:this.main_placement,
      subPlacement:this.sub_placement
    }
  },
  beforeUnmount() {


  },
  methods: {

    loadOrganization() {


      for (let index in this.members) {
        let user = this.members[index];
        if (user.managerType != 1) continue;
        if (this.organizationMemberMap[user.managerId] == null) {
          getOrganizationUser(user.managerId).then(res => {
            this.organizationMemberMap[user.managerId] = res.data.data;
          }).catch(e => {
            console.error("getOrganizationMembers err==>" + e);
            this.$message.error("获取组织成员失败")
          })

        }
      }


    },onshow(){
      this.mainPlacement=this.main_placement;
      this.$nextTick(()=>{
        let rect = this.$refs.content.$el.getBoundingClientRect();

        if(rect.y+rect.height+20>document.body.clientHeight){

          let innerRect = this.$refs.inner.getBoundingClientRect();
          if(innerRect.y-rect.height>0){
            this.mainPlacement='top'
          }
          else{
            this.mainPlacement='left'
          }
        }else{
          this.mainPlacement=this.main_placement;
        }



      })


    },
    onhide(){

      this.mainPlacement=this.main_placement;

    },




  }
}
</script>

<style scoped>
.members {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}


.user {
  display: flex;
  align-items: center;
  position: relative;
  padding: 5px 10px;

}

.user-info {

  position: absolute;

  background: white;
  width: 200px;
  box-shadow: rgb(60 64 67 / 15%) 0 1px 3px 1px;
  z-index: 9999999;


  top: 0;
  left: 0;


}

.user:hover {
  background: #ddd;
}


.organization-menu {
  background: white;
  box-shadow: rgb(60 64 67 / 15%) 0 1px 3px 1px;
  left: 200px;
  z-index: 99;

}

.stage-order {
  width: 28px;
  height: 28px;
  text-align: center;
  line-height: 28px;
  border-radius: 20px;
  color: white;
  overflow: hidden;
  position: relative;


  flex-shrink: 0;
  margin-right: -3px;
  border: solid 1px rgba(255, 255, 255, 0.3);
}

.name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user:hover .organization-menu {
  position: absolute;
  display: block;
  top: 0;

}

.organization-member div{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.organization-member {
  background: white;

  padding: 5px 10px;
  display: flex;
  align-items: center;



}

.organization-member div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.organization-member:hover {
  background: #dddddd;
}
.user,.organization-member{
}
</style>
